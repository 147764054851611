import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere, useTexture, useVideoTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import CloudsEarth from './CloudsEarth';
import LinesEarth from './LinesEarth';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function Earth(props) {
    
    // const ceva = useVideoTexture("render.mp4"); // .mp4 or .webm (plot twist: iPhone doesn't support WebM video playback natively) !!!!
    
    const materialEarth = new MeshBasicMaterial({ map: props.textures.textureEarth })
    const earthRef = useRef();

    useEffect(() => {
        if (earthRef) {
            gsap.to(earthRef.current.rotation, {
                y: "-3",
                duration: 50,
                repeat: -1,
                yoyo: true,
                ease: 'power1.inOut',
            });
        }
    }, []);
    
    return (
        <>
            <LinesEarth textures={props.textures} position={props.position} scale={1}/>
            <CloudsEarth textures={props.textures} position={props.position} sphere={props.sphere} scale={1.2} />
            <mesh ref={earthRef} position={props.position} geometry={props.sphere} material={materialEarth} scale={props.scale} />
        </>
    );
}

