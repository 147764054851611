import logo from './logo.svg';
import './App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, Plane, PresentationControls, useTexture } from '@react-three/drei';
import Lights from './Lights';
import Planet from './Objects/Planet';
import { MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';

export default function Background(props) {
    
    return (
        <>

            {/* center */}
            <Plane args={[12, 7, 1, 1]} position={[0, 0, -1]} rotation={[0, 0, 0]} scale={1.2}>
                <meshBasicMaterial
                    map={props.textures.background}
                    attach="material"
                />
            </Plane>

        </>
    );
}

