import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function CloudsDAO(props) {
    const cloudRef = useRef(null);

    useEffect(() => {
        if (cloudRef) {
            gsap.to(cloudRef.current.position, {
                x: -0.1,
                duration: 10,
                repeat: -1,
                yoyo: true,
                ease: 'power1.inOut',
            });
        }
    }, []);

    const material = new MeshBasicMaterial({ map: props.textures.cloudsDao, transparent: true });

    return (
        <>
            {/* <mesh ref={cloudRef} position={props.position} geometry={props.sphere} material={materialTransparent} scale={props.scale} /> */}
            <Plane ref={cloudRef} args={[8, 8]} position={props.position} rotation={[0, 0, 0]} material={material} scale={props.scale} />
        </>
    );
}
