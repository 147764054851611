import logo from '../../logo.svg';
import '../../App.css';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import { MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry } from 'three';

import {useLoader } from '@react-three/fiber';
import {TextureLoader } from 'three';
export default function LinesSaturn(props) {

    const material = new MeshBasicMaterial({ map: props.textures.linesSaturn, transparent:true });

    return (
        <>
            <Plane args={[1,1]} position={props.position} material={material} rotation={[0, 0, 0]} scale={props.scale}/>
        </>
    );
}

