import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry, TextureLoader } from 'three';

export default function X(props) {

    const material = new MeshStandardMaterial({ map: props.textures.xDao, transparent: true }); // standard material if want to change the color intensity based on stuff

    return (
        <>
            <Plane args={[1,1]} position={props.position} material={material} rotation={[-0.3, 0, 0]} scale={props.scale} />
        </>
    );
}

