import logo from './logo.svg';
import './App.css';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';

export default function Lights(props) {
  return (
    <>
        {/* <pointLight position={[0,2.2,0.3]} intensity={5} color={'white'}/> */}
        {/* <ambientLight intensity={3.5} color={'white'}/> */}
        <pointLight ref={props.lightDaoRef} position={[0,0.5,2]} intensity={5.7} color={'white'}/> 
    </>
  );
}

