import logo from '../../logo.svg';
import '../../App.css';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshDepthMaterial, MeshStandardMaterial, PlaneGeometry, SRGBColorSpace, SphereGeometry } from 'three';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function Spaceship1(props) {

    const material = new MeshBasicMaterial({ map: props.textures.ship1Gold, transparent: true});
    const spaceRef1 = useRef();

    useEffect(() => {
        
        gsap.to(spaceRef1.current.position, {
            duration: 55, // spaceship2 duration until reaching end (in sec)
            x: spaceRef1.current.position.x+0.6, // target x position
            // ease: "power1.out" // easing function
            repeat: -1,
            yoyo: true,
            ease: 'power1.inOut',
        });
    })

    return (
        <>
            <Plane ref={spaceRef1} args={[3, 1]} position={props.position} material={material} rotation={[0, 0, 0]} scale={props.scale} />
        </>
    );
}