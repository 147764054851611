import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere } from '@react-three/drei';
import { CircleGeometry, MeshStandardMaterial, SphereGeometry } from 'three';
import Faction1 from './All factions/Faction1';
import Faction2 from './All factions/Faction2';
import Faction3 from './All factions/Faction3';
import Faction4 from './All factions/Faction4';
import Faction5 from './All factions/Faction5';

export default function FactionManager(props) {

    const discGeometry = new CircleGeometry(1.15, 32);
    return (
        <>
            <Faction1 position={[1.3, 0.4, 0]} sphere={props.sphere} textures={props.textures} scale={0.18} geometry={discGeometry}/>
            <Faction2 position={[-1.3, 0.4, 0]} sphere={props.sphere} textures={props.textures} scale={0.18} geometry={discGeometry} />
            <Faction3 position={[-1, 1.2, 0]} sphere={props.sphere} textures={props.textures} scale={0.18} geometry={discGeometry} />
            <Faction4 position={[1, 1.2, 0]} sphere={props.sphere} textures={props.textures} scale={0.18} geometry={discGeometry} />
            <Faction5 position={[0, 1.5, 0]} sphere={props.sphere} textures={props.textures} scale={0.18} geometry={discGeometry} />
        </>
    );
}

