import logo from '../../logo.svg';
import '../../App.css';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import { DoubleSide, MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry } from 'three';

import {useLoader } from '@react-three/fiber';
import {TextureLoader } from 'three';
export default function Spaceships(props) {

    const moon = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/spaceport/spaceport_ships_full.png`);
    moon.repeat.set(1, 1);
    const materialMoon = new MeshBasicMaterial({ map: moon, transparent: true });

    return (
        <>
            <Plane args={[1.1, 1.4]} position={props.position} material={materialMoon} rotation={[0, 0, 0]} scale={props.scale} />
        </>
    );
}

