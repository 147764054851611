import './App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, Plane, PresentationControls, useTexture } from '@react-three/drei';
import Lights from './Lights';
import Planet from './Objects/Planet';
import { MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import logo from './logo.png'
import { useEffect } from 'react';

export default function LoadingScreen(props) {
    useEffect(() => {
        if (props.progress > 99.9) { // if it's loaded, add the animation
            setTimeout(() => {
                const loadingScreenElement = document.getElementById('loadingscreen');
                const logoLoadingScreen = document.getElementById('logoLoadingScreen');
                const textLoadingScreen = document.getElementById('textLoadingScreen');
                if (loadingScreenElement && logoLoadingScreen && textLoadingScreen) {
                    loadingScreenElement.classList.add('loaded');
                    logoLoadingScreen.classList.add('loaded');
                    textLoadingScreen.classList.add('loaded');
                }
            }, 1500)
        }
    }, [props.progress])
    return (
        <>
            <span id='loadingscreen' className='loadingscreen' style={{ position: 'fixed', width: '100%', height: '100%', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img id='logoLoadingScreen' className='logoLoadingScreen' src={logo} />
                <span id='textLoadingScreen' className='textLoadingScreen'>{(props.progress).toFixed(2)}/100 DNA loaded into procedure...</span>
            </span>
        </>
    );
}


