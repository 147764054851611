import '../../../App.css';
import { Canvas, useFrame } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import { CircleGeometry, MeshBasicMaterial, MeshStandardMaterial, SphereGeometry } from 'three';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

import {useLoader } from '@react-three/fiber';
import {TextureLoader } from 'three';

export default function Faction1(props) {
    const lines = new MeshBasicMaterial({ map: props.textures.linesFactions, transparent: true });
    const texture = new MeshBasicMaterial({ map: props.textures.faction5, transparent: true });
    const lineRef = useRef(null);
    const [isMouseOver, setIsMouseOver] = useState(false);

    // rotate the lines using useFrame 
    useFrame(() => {
        if (lineRef.current && isMouseOver) { // do it only on hover
            lineRef.current.rotation.z += 0.01; // rotation speed for the lines
        }
    });

    

    return (
        <>
            <Plane
                ref={lineRef}
                args={[1, 1]}
                position={[1.3, 0.4, 0.05]}
                material={lines}
                rotation={[0, 0, 0]}
                scale={0.45}
                onPointerOver={(e) => { setIsMouseOver(true); }}
                onPointerOut={(e) => { setIsMouseOver(false); }}
            />
            <mesh
                onPointerOver={(e) => { setIsMouseOver(true); }}
                onPointerOut={(e) => { setIsMouseOver(false); }}
                position={props.position}
                material={texture}
                geometry={props.geometry}
                scale={props.scale}
            />
        </>
    );
};