import logo from '../../logo.svg';
import '../../App.css';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import LinesSaturn from './LinesSaturn';
import CloudsSaturn from './CloudsSaturn';

import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';

export default function Saturn(props) {

    const normalTexture = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/earth/normal.png`);
    const heightTexture = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/earth/height.png`);
    const diffuseTexture = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/earth/diffuse.png`);

    const saturnTexture = new MeshStandardMaterial({
        map: diffuseTexture,
        normalMap: normalTexture,
        displacementMap: heightTexture,
        displacementScale: 0.1,
        roughness: 2,
        metalness: 0.1,
    });

    const xidRef = useRef(null);
    useEffect(() => {
        if (xidRef) {
            gsap.to(xidRef.current.rotation, {
                y: "5",
                duration: 50,
                repeat: -1, // Infinite repeat
                yoyo: true, // No need for yoyo in this case
                ease: 'power1.inOut', 
            });
        }
    }, []);

    return (
        <>
            <CloudsSaturn position={[props.position[0], props.position[1], props.position[2] + 0.4]} textures={props.textures} scale={1} />
            <LinesSaturn position={[props.position[0], props.position[1], props.position[2]]} textures={props.textures} scale={1.05} />
            <mesh ref={xidRef} position={props.position} geometry={props.sphere} material={saturnTexture} scale={props.scale} />
        </>
    );
}

