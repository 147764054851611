import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry, TextureLoader } from 'three';
import CloudsEarth from './CloudsEarth';
import { useRef } from 'react';

export default function LinesEarth(props) {

    const material = new MeshBasicMaterial({ map: props.textures.linesEarth, transparent:true });
    const lineRef = useRef();
    
    useFrame(() => {
            lineRef.current.rotation.z += 0.0003; // rotation speed for the lines
    });

    return (
        <>
            <Plane ref={lineRef} args={[5,5]} position={props.position} material={material} rotation={[0, 0, 0]} scale={props.scale}/>
        </>
    );
}

