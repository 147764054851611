
import React, { useEffect } from "react";
import gsap from 'gsap';

export default function Events(props) {


    useEffect(() => {

        if (props.streamDaoRef && props.streamDaoRef.current && props.animationDAOVote) {
            if (props.lightDaoRef.current.intensity === 5.7) {
                gsap.to(props.lightDaoRef.current, {
                    intensity: 7,
                    duration: 0.3,
                    ease: 'power1.inOut',
                });

                gsap.to(props.streamDaoRef.current.position, {
                    y: 1.55,
                    ease: 'power1.inOut', 
                });

                gsap.to(props.streamDaoRef.current.scale, {
                    x: 0.2,
                    y: 0.2,
                    z: 0.2,
                    ease: 'power1.inOut', 
                });

            } else {
                gsap.to(props.lightDaoRef.current, {
                    intensity: 5.7,
                    duration: 0.3,
                    ease: 'power1.inOut', 
                });

                gsap.to(props.streamDaoRef.current.position, {
                    y: 0.5,
                    ease: 'power1.inOut', 
                });

                gsap.to(props.streamDaoRef.current.scale, {
                    x: 0.05,
                    y: 0.05,
                    z: 0.05,
                    ease: 'power1.inOut', 
                });
            }
        }

        props.setAnimationDAOVote(false);
    }, [props.streamDaoRef, props.lightDaoRef, props.animationDAOVote])

    useEffect(() => {
        if (props.trainRef && props.trainRef.current && props.animationTrain) {
            // From [0.42, 0.33, 0.21] to [2.5, 1.75, 0.21]
            console.log(props.trainRef)
            console.log(props.trainRef.current.material);
            props.trainRef.current.position.set(0.15, 0.25, 0.2);
            props.trainRef.current.scale.set(0.38, 0.38, 0.38);

            // Only for reverting the action without waitting to end.
            gsap.killTweensOf(props.trainRef.current.position);
            gsap.killTweensOf(props.trainRef.current.scale);

            // Timeout for load
            gsap.to(props.trainRef.current.position, {
                duration: 5,
                x: 2.45, // target x position
                y: 1.6, // target y position
                z: 0.2, // target z position
                ease: "power1.out" // easing function
            });

            gsap.to(props.trainRef.current.scale, {
                duration: 5,
                x: 0.13, // target x position
                y: 0.13, // target y position
                z: 0.13, // target z position
                ease: "power1.out" // easing function
            });
        }
        props.setAnimationTrain(false);
    }, [props.trainRef, props.animationTrain])

    return (<>

    </>);
}

