import React, { useEffect, useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import Spaceships from './Spaceships';

export default function AnimatedSpaceport() {
  const mesh = useRef();

  const textures = useLoader(TextureLoader, Array.from({ length: 116 }, (_, i) => `${process.env.PUBLIC_URL}/spaceport/animation/spaceportAnim${i.toString().padStart(3, '0')}.webp`));

  const framesPerSecond = 30;
  const totalFrames = textures.length;

  useFrame((state) => {
    const currentFrame = Math.floor(state.clock.getElapsedTime() * framesPerSecond) % totalFrames;
    const material = mesh.current.material;

    material.map = textures[currentFrame];
    material.needsUpdate = true;
  });

  return (
    <>
    <mesh ref={mesh} position={[2.65, -1.25, 1]}>
      <planeGeometry args={[1.25, 2]} />
      <meshBasicMaterial map={textures[0]} transparent={true} />
    </mesh>
    <Spaceships position={[2.6, -1.2, 1]} scale={1.7} />
    </>
  );
};

