import logo from './logo.svg';
import './App.css';
import Scene from './Scene';
import Hud from './Hud';
import { Canvas } from '@react-three/fiber';
import { Suspense, useEffect, useRef, useState } from 'react';
import Events from './Events';
import { NoToneMapping } from 'three';
import { useProgress, useVideoTexture } from '@react-three/drei';
import LoadingScreen from './LoadingScreen';
import videoDivergent from './divergent.mp4';
import './fonts/futura-book.otf';


function App() {
  const trainRef = useRef(null);
  const [animationTrain, setAnimationTrain] = useState(null);
  const [animationDAOVote, setAnimationDAOVote] = useState(null);

  const { progress } = useProgress();

  useEffect(() => {
    console.log(progress);
  }, [progress])

  const [cnt, setCnt] = useState(0);
  const [inFocus, setInFocus] = useState(null);
  const controlsRef = useRef(null);
  const lightDaoRef = useRef(null);
  const streamDaoRef = useRef(null);

  return (
    <>
      <LoadingScreen progress={progress} />
      <Hud setInFocus={setInFocus} controlsRef={controlsRef} inFocus={inFocus} setAnimationTrain={setAnimationTrain} setAnimationDAOVote={setAnimationDAOVote} />
      <Events animationDAOVote={animationDAOVote} lightDaoRef={lightDaoRef} streamDaoRef={streamDaoRef} setAnimationDAOVote={setAnimationDAOVote} trainRef={trainRef} animationTrain={animationTrain} setAnimationTrain={setAnimationTrain} />
      <Canvas
        gl={{ antialias: true, toneMapping: NoToneMapping }}
        linear className='canvas' style={{ backgroundColor: 'black', width: '100%', height: '100%', position: 'fixed' }}> {/* flat or onCreated={({ gl }) => { gl.toneMapping = NoToneMapping }} */}
        <Suspense fallback={null}>
          <Scene streamDaoRef={streamDaoRef} lightDaoRef={lightDaoRef} controlsRef={controlsRef} setInFocus={setInFocus} trainRef={trainRef} inFocus={inFocus} />
        </Suspense>
      </Canvas>


      <video id="advert" loop crossOrigin="anonymous" playsInline muted autoPlay style={{
        display: 'none'
      }}>
        <source src={videoDivergent} type='video/mp4'></source>
      </video>
    </>
  );
}

export default App;
