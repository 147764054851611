import logo from './logo.svg';
import './App.css';
import { Canvas } from '@react-three/fiber';
import { Html, Loader, OrbitControls, PerspectiveCamera, PresentationControls, useProgress } from '@react-three/drei';
import Lights from './Lights';
import Planet from './Objects/Planet';
import { MeshStandardMaterial, SphereGeometry } from 'three';
import Background from './Background';
import { Suspense } from 'react';
import contur from './border2.webp';
import gsap from 'gsap';

export default function Hud(props) {

    const goToDapp = () => {
        window.open('https://dapp.projectx.mx/', '_blank');
    }

    return (
        <>
            <span className='projx-btn' style={{ position: 'fixed', top: '2rem', right: '2rem', zIndex: 1, border: 'solid 1px white', padding: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem', cursor: 'pointer' }} onClick={() => { goToDapp() }}>dApp</span>
            <span className='projx-btn' style={{ position: 'fixed', bottom: '2rem', left: '2rem', zIndex: 1, border: 'solid 1px white', padding: '0.5rem', paddingLeft: '5rem', paddingRight: '5rem', cursor: 'pointer' }}>
                {/* <span style={{position:'absolute', left:'0rem', top:'-3rem', border:'solid 1px white', padding:'0.3rem'}}>Discord</span>
                <span style={{position:'absolute', left:'5rem', top:'-3rem', border:'solid 1px white', padding:'0.3rem'}}>Telegram</span> */}
                Social Links
            </span>

            <span style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 3, pointerEvents: 'none' }}>
                <img src={contur} style={{ position: 'relative', width: '99%', height: '98%', objectFit: 'fill' }}></img>
            </span>

            <span style={{ position: 'fixed', bottom: '5rem', right: '2rem', zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>Simulate events</span>

            <span style={{ position: 'fixed', bottom: '2rem', right: '2rem', zIndex: 1, border: 'solid 1px white', padding: '0.5rem', paddingLeft: '0.5rem', paddingRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
                <span className='projx-btn' style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', cursor: 'pointer' }} onClick={() => { props.setAnimationTrain(true) }}>Train</span>
                <span className='projx-btn' style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', cursor: 'pointer' }} onClick={() => { props.setAnimationDAOVote(true) }}>DAO Vote</span>
                <span
                    className='projx-btn'
                    style={{ border: 'solid 1px white', paddingLeft: '1rem', paddingRight: '1rem', cursor: 'pointer' }}
                    onClick={() => { }}
                >
                    Transaction
                </span>

            </span>

            <span className={props.inFocus === "gold" ? 'windowGold show' : 'windowGold'} style={{ position: 'fixed', height: '80%', top: '10%', width: '70rem', left: "5rem", zIndex: 22, backgroundColor: 'rgba(0,0,0,0.4)' }}>
                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '90%', height: '90%', position: 'relative' }}>
                    <span style={{ position: 'fixed', top: '1.5rem', right: '1.5rem', fontSize:25, cursor:'pointer' }} onClick={()=>{props.controlsRef.current?.setLookAt(0, 0, 30, 0, 0, 1,true); props.setInFocus(null);}}>X</span>
                    <span style={{ fontSize: 40, height: '20%' }}>Some big title</span>
                    <span style={{ fontSize: 30, height: '70%' }}>
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                        Some smaller content here...
                    </span>
                </span>
            </span>

        </>
    );
}

