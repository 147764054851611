import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function StreamDAO(props) {

    const material = new MeshBasicMaterial({ map: props.textures.streamDao, transparent: true });

    useEffect(() => {
        if (props.streamDaoRef) {
            gsap.to(props.streamDaoRef.current.rotation, {
                z: "0.05",
                duration: 2,
                repeat: -1, // Infinite repeat
                yoyo: true, // No need for yoyo in this case
                ease: 'power1.inOut', 
            });
        }
    }, [props.streamDaoRef]);
    
    return (
        <>
            <Plane ref={props.streamDaoRef} args={[10, 10]} position={props.position} rotation={[0, 0, 0]} material={material} scale={0.05} />
        </>
    );
}
