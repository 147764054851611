import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

import {useLoader } from '@react-three/fiber';
import {TextureLoader } from 'three';
export default function SparklesGold(props) {

    const nori = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/gold/sparkles.png`);
    const materialTransparent = new MeshBasicMaterial({ map: props.textures.sparklesGold, transparent: true });


    return (
        <>
            {/* <mesh ref={cloudRef} position={props.position} geometry={props.sphere} material={materialTransparent} scale={props.scale} /> */}
            <Plane args={[7.7, 7.1]} position={props.position} rotation={[0, 0, -0.2]} material={materialTransparent} scale={0.2} />
        </>
    );
}
