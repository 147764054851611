import logo from '../../logo.svg';
import '../../App.css';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import { MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry, TextureLoader } from 'three';
import { useLoader } from '@react-three/fiber';

export default function LinesXID(props) {

    const moon = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/xid/lines.png`);
    moon.repeat.set(1, 1);
    const materialMoon = new MeshBasicMaterial({ map: moon, transparent:true });

    return (
        <>
            <Plane args={[1.2, 1.2]} position={props.position} material={materialMoon} rotation={[0, 0, 0]} scale={props.scale}/>
        </>
    );
}

