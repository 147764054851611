import { Canvas, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import { MeshBasicMaterial, MeshDepthMaterial, MeshLambertMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry, TextureLoader } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function Sun(props) {
    const material = new MeshBasicMaterial({ map: props.textures.sun, transparent:true });

    const sunRef = useRef(null);
    useEffect(() => {
        if (sunRef) {
            gsap.to(sunRef.current.rotation, {
                z: "10",
                duration: 80,
                repeat: -1, // Infinite repeat
                yoyo: true, // No need for yoyo in this case
                ease: 'power1.inOut', 
            });
        }
    }, []);

    return (
        <>
            <Plane ref={sunRef} args={[3, 3]} position={props.position} material={material} rotation={[0, 0, 0]} scale={props.scale} />
        </>
    );
}