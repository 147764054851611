import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import LinesDAO from './LinesGold';
import CloudsDAO from './SparklesGold';
import SparklesGold from './SparklesGold';
import LinesGold from './LinesGold';
import Spaceship1 from './Spaceship1';
import Spaceship2 from './Spaceship2';
import Train from '../Extras/Train/Train';
import Road from '../Extras/Train/Road';
import Ring from './RingGold';
import SingleSparkle from './SingleSparkle';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function Gold(props) {

    const material = new MeshBasicMaterial({ map: props.textures.textureGold, transparent: true });
    const refGold = useRef();
    
    const moveCamera = () => {
        props.setInFocus("gold");
        // Set a flag to indicate that setLookAt is called from moveCamera
        props.controlsRef.current?.setLookAt(1.5, 1.6, 10, 1.5, 1.6, 1, true);
    }


    return (
        <>
            <group ref={refGold} onClick={() => { moveCamera() }}>

                <Spaceship1 position={[props.position[0] + 0.5, props.position[1], props.position[2] + 0.1]} textures={props.textures} scale={0.3} />
                <Spaceship2 position={[props.position[0] - 0.7, props.position[1], props.position[2] + 0.1]} textures={props.textures} scale={0.3} />
                <SparklesGold position={[props.position[0], props.position[1] - 0.043, props.position[2] + 0.5]} textures={props.textures} scale={props.scale} />

                {/* <SparklesGold position={props.position} scale={props.scale} /> */}
                {/* <SingleSparkle scale={0.4}/> */}
                {/*<Ring position={props.position} scale={0.2}/> */}

                <LinesGold position={props.position} textures={props.textures} scale={props.scale + 0.65} />
                <mesh position={props.position} geometry={props.sphere} material={material} scale={props.scale} />
            </group>
        </>
    );
}

