import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import LinesDAO from './LinesDAO';
import CloudsDAO from './CloudsDAO';
import { useEffect, useRef } from 'react';
import X from './X';
import OutlineDAO from './OutlineDAO';
import StreamDAO from './StreamDAO';

export default function DAO(props) {

    const material = new MeshBasicMaterial({ map: props.textures.textureDao, transparent: true });
    const daoRef = useRef(null);
    // rotate the lines using useFrame 
    useFrame(() => {
        daoRef.current.rotation.y += 0.001; // rotation speed for the lines
    });

    return (
        <>
            <CloudsDAO position={[props.position[0], props.position[1], props.position[2]+1]} textures={props.textures} scale={0.2} />
            <StreamDAO position={[props.position[0], props.position[1]+0.2, props.position[2]-0.05]} textures={props.textures} scale={props.scale} streamDaoRef={props.streamDaoRef}/>
            <OutlineDAO position={[props.position[0], props.position[1], props.position[2]]} textures={props.textures} scale={props.scale} />
            <LinesDAO position={props.position} textures={props.textures} scale={props.scale + 1.1} />
            <X position={[props.position[0], props.position[1]+0.3, props.position[2]+0.7]} textures={props.textures} scale={props.scale+0.3} /> {/* add this inside group if want to spin with the sphere. */}
            {/* <group ref={daoRef}> */}
                <mesh ref={daoRef} position={props.position} geometry={props.sphere} material={material} scale={props.scale} />
            {/* </group> */}
        </>
    );
}


