import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import CloudsXID from './CloudsXID';
import LinesXID from './LinesXID';
import Advert from './Advert';

export default function XID(props) {

    const xid = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/xid.png`);
    xid.repeat.set(2, 1);
    const materialXID = new MeshBasicMaterial({ map: xid})

    return (
        <>
            <Advert position={[3.2, 0.3, 0.6]} scale={0.4} rotation={[0.15, -0.8, -1.6]}/>
            {/* <Advert position={[3.8, -0.1, 0.9]} scale={0.2} rotation={[0.6, 0.5, -1.6]}/> */}
            <LinesXID position={[props.position[0], props.position[1], props.position[2]]} textures={props.textures} scale={props.scale + 0.75} />
            <CloudsXID position={[props.position[0], props.position[1], props.position[2]]} sphere={props.sphere} textures={props.textures} scale={props.scale + 0.03} />
            <mesh position={props.position} geometry={props.sphere} material={materialXID} scale={props.scale} />
        </>
    );
}

