import { useEffect, useRef, useState } from 'react';
import '../../App.css';
import * as random from 'maath/random/dist/maath-random.esm'
import gsap from 'gsap';
import { useFrame } from '@react-three/fiber';
import { PointMaterial, Points } from '@react-three/drei';

export default function Stars(props) {
    const ref = useRef()
    const [sphere] = useState(() => random.inSphere(new Float32Array(1000), { radius: 10}))
    
    // Update mouse position on move
    useEffect(() => {
      // timeout for load
        // gsap.to(ref.current.scale, {
        //   duration: 3,
        //   x: 1, // target x position
        //   y: 1, // target y position
        //   z: 1, // target z position
        //   ease: "power1.out" // easing function
        // });
    }, [])
  
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 })
    // Update group position based on mouse position
    useFrame((state, delta) => {
      const x = mousePos.x / state.viewport.width - 0.5
      const y = mousePos.y / state.viewport.height - 0.5
      ref.current.position.x = x * 0.05
      ref.current.position.y = -y * 0.05
      ref.current.rotation.x -= delta / 10
    })
  
    return (
      <group
        ref={ref}
        position={[0, 0, 0]}
        rotation={[0, 0, Math.PI / 4]}
        scale={1}
      >
        <Points positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial transparent color="white" size={0.1} sizeAttenuation={true} depthWrite={false} />
        </Points>
        
      </group>
    )

}

