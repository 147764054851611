import React, { useRef } from 'react';
import { useMemo } from 'react';
import { Group, SphereGeometry, MeshBasicMaterial, Mesh, PlaneGeometry } from 'three';
import { extend, useFrame } from '@react-three/fiber';
import { LayerMaterial, Depth, Fresnel } from "lamina";
import CustomLayer from './CustomLayer'
import { VideoTexture } from 'three';
import { LinearFilter } from 'three';
import { SRGBColorSpace } from 'three';
import { Vector2 } from 'three';
import { AdditiveBlending } from 'three';
import { useEffect } from 'react';

import vertexShader from './shadersAdvert/vertexShader';
import fragmentShader from './shadersAdvert/fragmentShader';
import gsap from 'gsap';

export default function Advert(props) {
    const groupRef = useRef();
    const materialRef = useRef();
    const mesh = useRef();

    //video
    const video = document.getElementById('advert');

    video.addEventListener('play', function () {
        this.currentTime = 0;
    });

    setTimeout(() => {
        video.play();
    }, 2000);

    const videoTexture = new VideoTexture(video);
    videoTexture.colorSpace = SRGBColorSpace;
    videoTexture.minFilter = LinearFilter;

    const material = new MeshBasicMaterial({ color: 0xffffff, map: videoTexture });

    const fragmentShaderWithGlitches = `
        uniform sampler2D u_texture; // The video texture uniform
        uniform float u_time;

        varying vec2 vUv;

        void main() {
            vec2 rotatedUV = vec2(vUv.y, 1.0 - vUv.x);
        
            vec4 textureColor = texture2D(u_texture, rotatedUV);
        
            // Introduce glitches based on a random value and time
            float glitchFactor = abs(sin(u_time * 10.0)) * 0.5; // Adjusting frequency and amplitude
            vec3 glitchColor = vec3(textureColor.r + glitchFactor, textureColor.g, textureColor.b);
        
            gl_FragColor = vec4(glitchColor, 0.7); // Opacity for the advert
        }
    `;

    const uniforms = useMemo(
        () => ({
            u_time: {
                value: 1,
            },
            u_texture: {
                value: videoTexture,
            },
            u_curvature: {
                value: 1,
            }
        }), []
    );

    useFrame((state) => {
        const { clock } = state;
        mesh.current.material.uniforms.u_time.value = clock.getElapsedTime() * 1;
    });

    const goToSpecificAdvertLink = async () => {
        window.open('https://dapp.projectx.mx/', '_blank');
    }


    const mOver = () => {
        gsap.to(mesh.current.position, {
            duration: 1,
            x: -0.2, // target x position
            y: 0, // target y position
            z: 0, // target z position
            ease: "power1.out" // easing function
        });
    }


    const mOut = () => {
        gsap.to(mesh.current.position, {
            duration: 1,
            x: 0, // target x position
            y: 0, // target y position
            z: 0, // target z position
            ease: "power1.out" // easing function
        });
    }

    return (
        <group ref={groupRef} position={props.position} rotation={props.rotation} scale={props.scale}>
            <mesh ref={mesh} onClick={() => { goToSpecificAdvertLink() }} onPointerEnter={(e) => { mOver(); }} onPointerLeave={(e) => { mOut(); }}>
                <planeGeometry args={[1.2, 2.3, 40, 40]} />
                <shaderMaterial
                    transparent
                    fragmentShader={fragmentShader}
                    vertexShader={vertexShader}
                    uniforms={uniforms}
                // wireframe
                />
            </mesh>
        </group>
    );
};

