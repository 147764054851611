import { Canvas, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import { MeshBasicMaterial, MeshDepthMaterial, MeshLambertMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry, TextureLoader } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function SunLines(props) {

    const material = new MeshBasicMaterial({ map: props.textures.sunDots, transparent:true });

    return (
        <>
            <Plane ref={props.trainRef} args={[10, 5.5]} position={props.position} material={material} rotation={[0, 0, 0]} scale={1} />
        </>
    );
}