import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Plane, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../../Lights';
import { MeshBasicMaterial, MeshDepthMaterial, MeshLambertMaterial, MeshStandardMaterial, PlaneGeometry, SphereGeometry } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

import {useLoader } from '@react-three/fiber';
import {TextureLoader } from 'three';

export default function Train(props) {
    const moon = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/misc/train2.png`);
    moon.repeat.set(1, 1);

    const materialMoon = new MeshBasicMaterial({ map: moon, transparent: true});

    return (
        <>
            <Plane ref={props.trainRef} args={[3,1]} position={props.position} material={materialMoon} rotation={[0, 0, 0.528]} scale={props.scale} />
        </>
    );
}