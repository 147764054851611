import logo from '../../logo.svg';
import '../../App.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, PivotControls, Sphere, useTexture } from '@react-three/drei';
import Lights from '../../Lights';
import { MeshBasicMaterial, MeshStandardMaterial, SphereGeometry, TextureLoader } from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function CloudsXID(props) {
    const cloudRef = useRef(null);

    useEffect(() => {
        if (cloudRef) {
            gsap.to(cloudRef.current.rotation, {
                y: "5",
                duration: 50,
                repeat: -1,
                yoyo: true,
                ease: 'power1.inOut',
            });
        }
    }, []);

    const nori = useLoader(TextureLoader, `${process.env.PUBLIC_URL}/nori.png`);
    const materialTransparent = new MeshBasicMaterial({ map: nori, transparent: true });

    return (
        <>
            <mesh ref={cloudRef} position={props.position} geometry={props.sphere} material={materialTransparent} scale={props.scale} />
        </>
    );
}
