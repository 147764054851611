import logo from './logo.svg';
import './App.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { CameraControls, Html, Loader, OrbitControls, PerspectiveCamera, PresentationControls, SoftShadows, StatsGl, useProgress, useTexture, useVideoTexture } from '@react-three/drei';
import Lights from './Lights';
import Planet from './Objects/Planet';
import { Box3, MOUSE, MeshStandardMaterial, SphereGeometry, TextureLoader, Vector3 } from 'three';
import Background from './Background';
import { Suspense, useEffect, useRef, useState } from 'react';
import Earth from './Objects/Earth/Earth';
import DAO from './Objects/DAO/DAO';
import CloudsXID from './Objects/XID/CloudsXID';
import CloudsSaturn from './Objects/XID/CloudsXID';
import CloudsEarth from './Objects/Earth/CloudsEarth';
import Smoke from './Objects/Extras/Smoke';
import XID from './Objects/XID/XID';
import Saturn from './Objects/Saturn/Saturn';
import { useControls } from 'leva';
import Stars from './Objects/Extras/Stars';
import Gold from './Objects/Gold/Gold';
import Factions from './Objects/Factions/FactionManager';
import FactionManager from './Objects/Factions/FactionManager';
import Sun from './Objects/Extras/Sun';
import SunLines from './Objects/Extras/SunLines';
import Spaceport from './Objects/Spaceport/Spaceport';
import Road from './Objects/Extras/Train/Road';
import Train from './Objects/Extras/Train/Train';
import RoadLine from './Objects/Extras/Train/Rail';
import Rail from './Objects/Extras/Train/Rail';
import Ring from './Objects/Ring/Ring';
import AnimatedSpaceport from './Objects/Spaceport/AnimatedSpaceport';
import AnimatedRing from './Objects/Ring/AnimatedRing';

export default function Scene(props) {

    const material = new MeshStandardMaterial({ color: '#6e6e6e', roughness: 0.6 })
    const sphere = new SphereGeometry(1, 28, 28);
    const camera = useRef();

    // // useControls hook to create GUI controls for the camera
    // const { fov } = useControls({
    //     fov: {
    //         value: 10, // Initial field of view
    //         min: 0,    // Minimum field of view
    //         max: 100,  // Maximum field of view
    //         step: 1,   // Step size for the field of view
    //     },
    //     // positionZ: {
    //     //     value: 30, // Initial field of view
    //     //     min: 0,    // Minimum field of view
    //     //     max: 500,  // Maximum field of view
    //     //     step: 1,   // Step size for the field of view
    //     // },
    // });

    // useFrame hook to update the camera's fov on each frame
    // useFrame(() => {
    //     camera.current.fov = fov;
    //     camera.current.updateProjectionMatrix();
    // });


    const setBoundary = () => {
        if (props.controlsRef) {
            // Zoom ranges
            const zoomUpperLimit = 30; // Upper limit of your zoom range, smaller boundary
            const zoomLowerLimit = 20; // Lower limit of your zoom range, larger boundary

            // Normalizez the zoom value to a 0-1 range where 0 corresponds to zoom 30 and 1 to zoom 20
            const normalizedZoom = (props.controlsRef.current.distance - zoomUpperLimit) / (zoomLowerLimit - zoomUpperLimit);

            // Defined the boundary box sizes for zoom 30 (min size)
            const minSizeAtMaxZoom = new Vector3(0, 0, 0);
            const maxSizeAtMaxZoom = new Vector3(0, 0, 0);

            // Defined the boundary box sizes for zoom 20 (max size)
            const minSizeAtMinZoom = new Vector3(-1.5, -1, -0.5);
            const maxSizeAtMinZoom = new Vector3(1.5, 1, 0.5);

            // Linearly interpolate between the sizes based on the normalized zoom
            const interpolatedMin = minSizeAtMaxZoom.clone().lerp(minSizeAtMinZoom, normalizedZoom);
            const interpolatedMax = maxSizeAtMaxZoom.clone().lerp(maxSizeAtMinZoom, normalizedZoom);

            const boundaryBox = new Box3(interpolatedMin, interpolatedMax);
            props.controlsRef.current.setBoundary(boundaryBox);
        }
        props.setInFocus(null);
    }

    const textures = useTexture({
        // misc
        background: `${process.env.PUBLIC_URL}/misc/webp/background.webp`,
        border: `${process.env.PUBLIC_URL}/misc/webp/border.webp`,
        rail: `${process.env.PUBLIC_URL}/misc/webp/rail.webp`,
        road: `${process.env.PUBLIC_URL}/misc/webp/road.webp`,
        sun: `${process.env.PUBLIC_URL}/misc/webp/sun.webp`,
        sunDots: `${process.env.PUBLIC_URL}/misc/webp/sunDots.webp`,
        train: `${process.env.PUBLIC_URL}/misc/webp/train.webp`,

        // dao
        cloudsDao: `${process.env.PUBLIC_URL}/dao/webp/cloudsDao.webp`,
        haloDao: `${process.env.PUBLIC_URL}/dao/webp/haloDao.webp`,
        outlineDao: `${process.env.PUBLIC_URL}/dao/webp/outlineDao.webp`,
        linesDao: `${process.env.PUBLIC_URL}/dao/webp/linesDao.png`,
        streamDao: `${process.env.PUBLIC_URL}/dao/webp/streamDao.webp`,
        textureDao: `${process.env.PUBLIC_URL}/dao/webp/textureDao.webp`,
        xDao: `${process.env.PUBLIC_URL}/dao/webp/xDao.webp`,

        // earth
        cloudsEarth: `${process.env.PUBLIC_URL}/earth/webp/cloudsEarth.webp`,
        linesEarth: `${process.env.PUBLIC_URL}/earth/webp/linesEarth.webp`,
        textureEarth: `${process.env.PUBLIC_URL}/earth/earth.jpg`,

        // factions
        linesFactions: `${process.env.PUBLIC_URL}/factions/webp/linesFactions.png`,
        faction1: `${process.env.PUBLIC_URL}/factions/webp/faction1.webp`,
        faction2: `${process.env.PUBLIC_URL}/factions/webp/faction2.webp`,
        faction3: `${process.env.PUBLIC_URL}/factions/webp/faction3.webp`,
        faction4: `${process.env.PUBLIC_URL}/factions/webp/faction4.webp`,
        faction5: `${process.env.PUBLIC_URL}/factions/webp/faction5.webp`,

        // gold
        sparklesGold: `${process.env.PUBLIC_URL}/gold/webp/sparkles.png`,
        linesGold: `${process.env.PUBLIC_URL}/gold/webp/linesGold.webp`,
        ship1Gold: `${process.env.PUBLIC_URL}/gold/webp/ship1Gold.webp`,
        ship2Gold: `${process.env.PUBLIC_URL}/gold/webp/ship2Gold.webp`,
        textureGold: `${process.env.PUBLIC_URL}/gold/webp/textureGold.webp`,

        // ring
        asteroids1: `${process.env.PUBLIC_URL}/ring/webp/asteroids1.webp`,
        asteroids2: `${process.env.PUBLIC_URL}/ring/webp/asteroids2.webp`,
        asteroids3: `${process.env.PUBLIC_URL}/ring/webp/asteroids3.webp`,
        ring: `${process.env.PUBLIC_URL}/ring/webp/ring.webp`,
        ringSpaceship: `${process.env.PUBLIC_URL}/ring/webp/ringSpaceship.webp`,

        // saturn
        cloudsSaturn: `${process.env.PUBLIC_URL}/saturn/webp/cloudsSaturn.webp`,
        linesSaturn: `${process.env.PUBLIC_URL}/saturn/webp/linesSaturn.webp`,

        // spaceport
        ship1: `${process.env.PUBLIC_URL}/spaceport/webp/ship1.webp`,
        ship2: `${process.env.PUBLIC_URL}/spaceport/webp/ship2.webp`,
        ship3: `${process.env.PUBLIC_URL}/spaceport/webp/ship3.webp`,
        ship4: `${process.env.PUBLIC_URL}/spaceport/webp/ship4.webp`,
        ship5: `${process.env.PUBLIC_URL}/spaceport/webp/ship5.webp`,
        spaceport: `${process.env.PUBLIC_URL}/spaceport/spaceport.png`,
        spaceportHighlights: `${process.env.PUBLIC_URL}/spaceport/spaceportHighlights.png`,
        spaceportShield: `${process.env.PUBLIC_URL}/spaceport/spaceportShield.png`
    })


    // useEffect(() => {
    //     if (props.controlsRef.current) {
    //         // Apply scaling factor to bounding box dimensions
    //         const bb = new Box3(
    //             new Vector3(-1, -1, -20),
    //             new Vector3(1, 1, 30)
    //         );

    //         props.controlsRef.current._boundary = bb;
    //     }
    // }, [props.controlsRef, props.controlsRef.current]);


    // var texturess = [];
    // var textureCount = 30; // Number of frames in the sequence

    // for (var i = 1; i <= textureCount; i++) {
    //     var texture = useTexture('path/to/your/sequence/frame_' + i + '.png');
    //     texturess.push(texture);
    // }

    return (
        <>
            {/* <StatsGl className="stats" /> */}
            <PerspectiveCamera ref={camera} makeDefault position={[0, 0, 30]} fov={10} />
            <Lights lightDaoRef={props.lightDaoRef} />
            <CameraControls
                mouseButtons={{ left: 2, middle: 8, right: 2, wheel: 8 }}
                onStart={(e) => { setBoundary() }}
                ref={props.controlsRef}
                enabled={true} // enable or disable controls
                enableZoom={true} // enable or disable zooming
                minDistance={20} // zoom min
                maxDistance={30}  // zoom max 
                enablePan={true} // enable or disable panning
                enableRotate={true} // enable or disable rotation
                minPolarAngle={1.5} // minimum polar angle
                maxPolarAngle={1.5} // maximum polar angle
                minAzimuthAngle={-0.1} // minimum azimuth angle
                maxAzimuthAngle={0} // maximum azimuth angle  
                boundaryEnclosesCamera={false} // Careful to not have set this on true, otherwise camera will tend to lower the Z axis towards the end.
                // smoothTime={1} 
                panSpeed={1} // pan speed factor
                rotateSpeed={1} // rotation speed factor
                zoomSpeed={0.2} // zoom speed factor
                // enableDamping={true} // enable or disable damping (smoothness)
                autoRotate={false} // enable or disable auto-rotation
                autoRotateSpeed={2} // auto-rotation speed factor
            />
            <Background textures={textures} />
            <Earth position={[0, -3.5, 0.1]} sphere={sphere} textures={textures} scale={2.3} />
            <Saturn position={[-3.2, 0, 0]} sphere={sphere} textures={textures} scale={0.4} />
            <XID position={[3.6, 0.25, 0]} sphere={sphere} textures={textures} scale={0.6} />
            <DAO position={[0, 0.3, 0]} sphere={sphere} textures={textures} material={material} scale={0.7} streamDaoRef={props.streamDaoRef} />
            <Gold inFocus={props.inFocus} setInFocus={props.setInFocus} controlsRef={props.controlsRef} position={[2.6, 1.6, 0]} sphere={sphere} textures={textures} material={material} scale={0.4} trainRef={props.trainRef} />
            <Train position={[0.15, 0.1, 0.1]} textures={textures} scale={0.13} trainRef={props.trainRef} />
            <Road position={[1.4, 1, 0.3]} textures={textures} scale={0.92} />
            <Rail position={[1.4, 1, 0.3]} textures={textures} scale={0.92} />
            <FactionManager sphere={sphere} textures={textures} material={material} />
            <Sun position={[0, 2.7, -0.9]} textures={textures} />
            <SunLines position={[0, 0, -0.5]} textures={textures} />
            <AnimatedSpaceport />
            {/* <Spaceport position={[2.7, -1.2, 0]} sphere={sphere} scale={2.7} material={material} />  */}
            {/* <Ring position={[-3.5, 1.5, 0]} sphere={sphere} scale={1.4} textures={textures} material={material} /> */}
            {/* <AnimatedRing position={[-3.5, 1.5, 0]} scale={1.3} textures={textures}/> */}
            <Stars />
        </>
    );
}

